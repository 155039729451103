import { useState, useEffect } from "react";
import { useBle } from "../../context/BleContext";
import * as ble from "../../services/ble/";
import toast from "react-hot-toast";

import { FaEye, FaEyeSlash } from "react-icons/fa6";

export default function FormInverter({
  updateCompletion,
}: {
  updateCompletion: (isCompleted: boolean) => void;
}) {
  interface Inverter {
    id: number; // Assuming an ID as a number
    name: string;
    proto: string;
  }

  const bleApi = useBle();

  const [ipAddress, setIpAddress] = useState<string>("");
  const [port, setPort] = useState<number>();
  const [serial, setSerial] = useState<string>("");
  const [deviceAddress, setDeviceAddress] = useState<number>();
  const [selectedInverter, setSelectedInverter] = useState<string>("");
  const [selectedMake, setSelectedMake] = useState<string>(""); // selected inverter type
  const [selectedProtocol, setSelectedProtocol] = useState<string>("");
  const [supportedInverters, setSupportedInverters] = useState<Inverter[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScanning, setIsScanning] = useState(false);

  const [enphase_username, setEnphaseUsername] = useState<string>("");
  const [enphase_password, setEnphasePassword] = useState<string>("");
  const [enphase_gw_serial, setEnphaseGwSerial] = useState<string>("");

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateCompletion(true);
    setIsSubmitting(true);

    // Base type for all payloads
    type BasePayload = {
      connection: string;
      device_type: string;
    };

    // Protocol-specific payload types
    type TcpPayload = BasePayload & {
      ip: string;
      port: number;
      slave_id: number;
    };

    type SolarmanPayload = TcpPayload & {
      sn: number;
    };

    type SunspecPayload = BasePayload & {
      ip: string;
      port: number;
      slave_id: number;
    };

    type RestPayload = BasePayload & {
      ip: string;
      iq_gw_serial: string;
      username: string;
      password: string;
    };

    let payload: TcpPayload | SolarmanPayload | SunspecPayload | RestPayload;

    // Set protocol-specific payload
    switch (selectedProtocol) {
      case "sol":
        payload = {
          connection: "SOLARMAN",
          device_type: selectedMake,
          ip: ipAddress,
          port: Number(port),
          slave_id: Number(deviceAddress),
          sn: Number(serial),
        };
        break;

      case "sunspec":
        payload = {
          connection: "SUNSPEC",
          device_type: selectedMake,
          ip: ipAddress,
          port: Number(port),
          slave_id: Number(deviceAddress),
        };
        break;

      case "rest":
        payload = {
          connection: "ENPHASE",
          device_type: selectedMake,
          ip: ipAddress,
          iq_gw_serial: enphase_gw_serial,
          username: enphase_username,
          password: enphase_password,
        };
        break;

      default: // TCP
        payload = {
          connection: "TCP",
          device_type: selectedMake,
          ip: ipAddress,
          port: Number(port),
          slave_id: Number(deviceAddress),
        };
    }

    try {
      let endpoint;

      endpoint = ble.API_CREATE_DEVICE;

      const response = await bleApi.fetch(endpoint, ble.Method.POST, payload);

      console.log("Inverter Modbus Config: ", response);
      const toastId = toast.loading("Waiting for inverter to open...");

      setTimeout(async () => {
        const status_response = await bleApi.fetch(
          ble.API_INVERTER,
          ble.Method.GET,
          {},
        );
        toast.remove(toastId);
        if (status_response.payload.status === "open") {
          // if (
          //   status_response.payload.host === payload.ip &&
          //   ("port" in payload
          //     ? status_response.payload.port === payload.port
          //     : true)
          // ) {
          //   toast.success("Inverter connected successfully");
          // } else {
          //   toast.error(
          //     "Could not connect to inverter at: " +
          //       payload.ip +
          //       ("port" in payload ? ":" + payload.port : ""),
          //   );
          // }
          toast.success("Inverter connected successfully");
        } else {
          toast.error("Inverter connection failed");
        }
        setIsSubmitting(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    console.log("Fetch the supported inverter list");

    async function getSupported() {
      try {
        const r = await bleApi.fetch(
          ble.API_INVERTER_SUPPORTED,
          ble.Method.GET,
          {},
        );
        console.log("Supported Inverters: ", r.payload);
        const processedInverters = r.payload.inverters.map((inverter: any) => {
          if (Array.isArray(inverter)) {
            return {
              id: inverter[0],
              name: inverter[1],
              proto: inverter[2],
            };
          } else {
            return {
              id: inverter.name,
              name: inverter.dname,
              proto: inverter.proto,
            };
          }
        });
        setSupportedInverters(processedInverters);
      } catch (error) {
        console.log(error);
      }
    }
    getSupported();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    const [inverterMake, inverterProtocol] = value.split("|");

    setSelectedInverter(value);
    setSelectedMake(inverterMake);
    setSelectedProtocol(inverterProtocol);

    if (inverterProtocol !== "sol") {
      setSerial(""); // Resets serial to an empty string
    }
  };

  const handleScan = async () => {
    setIsScanning(true);
    try {
      const scanResult = await bleApi.fetch(
        ble.API_SCAN_INVERTER,
        ble.Method.GET,
        {},
      );
      if (
        scanResult.payload.devices &&
        scanResult.payload.devices[0].host &&
        scanResult.payload.devices[0].port
      ) {
        setIpAddress(scanResult.payload.devices[0].host);
        setPort(scanResult.payload.devices[0].port);
        toast.success(
          "Inverter found: " +
            scanResult.payload.devices[0].host +
            ":" +
            scanResult.payload.devices[0].port,
        );
      } else {
        toast.error("No inverter found");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error scanning for inverter");
    } finally {
      setIsScanning(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-2 w-full xl:w-[600px]">
      <div className="grid grid-cols-1 items-center gap-2">
        <div className="flex flex-col">
          <label htmlFor="type">Type:</label>
          <select
            className="rounded-lg p-2 text-black outline-none"
            id="type"
            disabled={isSubmitting}
            required
            value={selectedInverter}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Select an inverter
            </option>
            {supportedInverters.map((inverter) => (
              <option
                key={inverter.id}
                value={`${inverter.id}|${inverter.proto}`}
              >
                {inverter.name}
              </option>
            ))}
          </select>
        </div>

        {selectedInverter && (
          <>
            <div className="flex flex-col">
              <label htmlFor="ip">IP Address:</label>
              <input
                className="rounded-lg p-2 text-black outline-none"
                id="ip"
                disabled={isSubmitting}
                placeholder="192.168.10.10"
                required
                value={ipAddress}
                onChange={(e) => setIpAddress(e.target.value)}
              />
            </div>
            {selectedProtocol !== "rest" && (
              <div className="flex flex-col">
                <label htmlFor="port">Port:</label>
                <input
                  className="rounded-lg p-2 text-black outline-none"
                  id="port"
                  disabled={isSubmitting}
                  placeholder="502"
                  required
                  value={port}
                  onChange={(e) => setPort(Number(e.target.value))}
                />
              </div>
            )}
            {selectedProtocol !== "rest" && (
              <div className="flex flex-col">
                <label htmlFor="address">Device Address:</label>
                <input
                  className="rounded-lg p-2 text-black outline-none"
                  id="address"
                  disabled={isSubmitting}
                  placeholder="1"
                  required
                  onChange={(e) => setDeviceAddress(Number(e.target.value))}
                />
              </div>
            )}
            {selectedProtocol === "rest" && (
              <>
              <div className="flex flex-col">We will collect an access token from the Enphase API using your Enphase credentials. Your credential are not saved after the token has been accuired.</div>
                <div className="flex flex-col">
                  <label htmlFor="username">Enphase Username:</label>
                <input
                  className="rounded-lg p-2 text-black outline-none"
                  id="username"
                  disabled={isSubmitting}
                  placeholder="Username"
                  required
                    onChange={(e) => setEnphaseUsername(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="password">Enphase Password:</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="rounded-lg p-2 text-black outline-none w-full pr-16"
                      id="password"
                      disabled={isSubmitting}
                      placeholder="Password"
                      required
                      onChange={(e) => setEnphasePassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FaEyeSlash size={20} />
                      ) : (
                        <FaEye size={20} />
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="gw_serial">Enphase IQ Gateway Serial:</label>
                  <input
                    className="rounded-lg p-2 text-black outline-none"
                    id="gw_serial"
                    disabled={isSubmitting}
                    placeholder="IQ Gateway Serial"
                    required
                    onChange={(e) => setEnphaseGwSerial(e.target.value)}
                  />
                </div>
              </>
            )}
            {selectedProtocol === "sol" && (
              <div className="flex flex-col">
                <label htmlFor="serial">Serial:</label>
                <input
                  className="rounded-lg p-2 text-black outline-none"
                  id="serial"
                  disabled={isSubmitting}
                  placeholder="Serial"
                  required
                  onChange={(e) => setSerial(e.target.value)}
                />
              </div>
            )}
            <div className="mt-2 flex justify-center">
              <button
                type="button"
                className={`mb-2 w-1/3 rounded-lg px-4 py-2 font-normal text-black transition-colors duration-300 ${
                  isScanning ? "cursor-not-allowed bg-gray-400" : "btn-green"
                }`}
                disabled={isScanning}
                onClick={handleScan}
              >
                {isScanning ? (
                  <span className="flex items-center justify-center">
                    <svg
                      className="mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    Scanning...
                  </span>
                ) : (
                  "Scan for Inverter"
                )}
              </button>
            </div>
          </>
        )}
      </div>
      <button className="btn-green mt-2 w-full" disabled={isSubmitting}>
        Submit
      </button>
    </form>
  );
}
